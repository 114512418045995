export default {
    categories: [{
        order: 1,
        hero: 3,
        name: 'Nova',
        slug: 'nova',
        date: '2007-2011',
        description: "The NOVA series focuses on the discovery of previously unrecorded species of flora and fauna. Most of the plants and animals in the series were discovered between 1990 and 2007. The number of species that remain “undiscovered” is difficult to estimate. Many scientists believe that of all living things on earth, perhaps only 10-15% have been given a Latin name and thus assigned a place in the big picture of evolution. <br/><br/>Each organism has been assigned a suitable place in one of the strata within a typical mid-elevation tropical forest: the forest floor, understory, canopy, or emergent layer. These plants and animals are from many continents, and would never be seen together or all at once, so the ecosystems depicted in Nova are hypothetical. In general, accurate representation of recently discovered flora and fauna like these is difficult. Often the only people with clear images and knowledge of these new plants and animals are the scientists who describe them. Scientific descriptions, specimens, drawings, and other artists’ and photographers’ images served as reference. <br/><br/>These paintings provide a glimpse of the variations that evolve as plants and animals make use of available niches, whether in the wilds of the world’s remaining forests or in our own backyards. While this story of discovery and change seems hopeful, often these creatures survive in a remnant of rapidly shrinking habitat. As John C. Sawhill, past president of The Nature Conservancy once said, “In the end, our society will be defined not only by what we create, but by what we refuse to destroy.”",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Forest Floor",
            slug: "forest-floor",
            year: "2007", 
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 60”",
            description: "FOREST FLOOR (2007) shows a stream habitat. A few of the species in the farthest background are depicted at half size, as noted by a percentage in parenthesis on the species list, but most are shown life size. Tropical rainforests are the oldest biomes on Earth, having survived the Ice Ages that so sculpted and changed the latitudes above and below the Equator. In general, the soil in an environment like the one pictured here—the floor beneath a mature rainforest—is nutrient-poor and shallow. Between 75 and 225 inches of rain per year washes nutrients and minerals away. Fallen leaves, branches, and other plant detritus are recycled quickly by teeming hordes of nearly invisible decomposers, including springtails, nematodes, tardigrades, bacteria, and fungi. The usable nutrients produced by their activities are promptly absorbed by vegetation."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Understory",
            slug: "understory",
            year: "2007", 
            medium: "Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "UNDERSTORY (2007) shows the middle level of an average (though imaginary) tropical forest. The branches of the flowering tree which form the underlying structure for this painting grow out of the tree’s main trunk in an unusual pattern of alternating V’s. This pattern is reminiscent of the diagrams (cladograms) used to sort species into phylogenetic or evolutionary order. Clados is Greek for “branch.” The array and number of birds and animals on these branches invokes the crowded conditions as more and more of our biodiversity is pushed into ever smaller plots of uninhabited land. Everything here is painted at life size."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Canopy",
            slug: "canopy",
            year: "2008", 
            medium: "Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "CANOPY (2008) shows the sunlit world where the tops of the rainforest’s tallest trees meet the sky. Terry Erwin, a beetle specialist at the Smithsonian Institution, calls the rainforest canopy “the last biological frontier.” This habitat was uncharted territory until the 1970s, when researchers began using high-tech mountaineering equipment to climb up into it and inflatable rafts to hover over it. A mature canopy tree is crowded with a wide range of flora, including mosses, hornworts, and liverworts that together form dense mats, lianas and ferns, and a host of epiphytes, such as orchids—some, no bigger than a thumbnail. Mature fully formed bonsais and even fruiting shrubs have taken root, coexisting with gardens planted and tended by ants, and a host of animals and birds, a veritable micro-Eden, hundreds of feet in the air."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Emergent",
            slug: "emergent",
            year: "2011", 
            medium: " Oil on polyester over panel", 
            dimensions: "60” x 48”",
            description: "Emergent (2011) depicts the uppermost layer of an imaginary, mid-elevation tropical forest, where the tallest trees emerge into full sun. In this airy part of the forest habitat, 200 feet above the ground, plants and animals exist that are seen nowhere else, and whose entire life cycles are spent in the trees. The trees themselves, mostly evergreen hardwoods, live very self-contained lives—most do not touch their neighbors at all. Species tend to bloom synchronously, though they are usually spaced widely apart—rarely do you see a stand of one kind of tree. Rainforests are so highly diversified that not only are the microscopic organisms of one tree distinct and different from those of other trees, but each leaf on an individual tree shows a remarkable diversity of mosses, liverworts, lichens, bacteria, and other minute organisms, often entirely different from its nearest leaf neighbor."
        }]
    },
    {
        order: 2,
        hero: 5,
        name: 'Taxa',
        slug: 'taxa',
        date: '1999-2004',
        description: "\"Taxa\" is a Greek word for “order” or “arrangement” and the root word of “taxonomy”: the scientific description of species and placement into evolutionary order. Each of the six canvases in this series explores one aspect or dynamic of conservation; the subject of each painting grew out of the research for the previous. Each took nearly a year to complete. In-depth study of a single species can yield a world of information: each life cycle is deeply entwined with that of the others in its community. The more we learn about the intricacies of one organism, its behavior and its ecosystem, the more we recognize the limits of our current knowledge. The more we discover and understand, the more we comprehend our responsibility for every other living thing.",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Descendant",
            slug: "descendant",
            year: "1999",
            medium: "Oil on panel", 
            dimensions: "48” x 36”",
            description: "Descendant depicts plants and animals all in decline in mainland United States, Hawaii, or Central America. While a few are ‘presumed extinct,’ most of them are on either state or federal endangered species lists. These plants and animals are all in decline in the mainland United States, Hawaii or Central America. While a few are “presumed extinct,” most of them are on either state or federal endangered species lists."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Ascendant",
            slug: "ascendant",
            year: "2000",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "This picture is of non-native species that have been introduced in some part of the United States or its trust territories. They are all on the increase as they are successfully out-competing native residents."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Trade",
            slug: "trade",
            year: "2001",
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 48”",
            description: "Wild populations of the species in this painting are depleted by collection for both legal and illegal markets.  Wildlife is the fourth largest black market commodity in the world, ranking behind guns, drugs, and humans. According to Interpol, the illegal trade in wildlife is valued at about twenty billion US dollars annually."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Collection",
            slug: "collection",
            year: "2002",
            medium: "Oil on polyester over panel", 
            dimensions: "36” x 48”",
            description: "This painting is an exploration of the human desire to own. Featured here are plants and animals that people want to study in depth, to exhibit, or simply to admire at leisure. Ownership of many of these species is thought to confer status on the owner. Many of the depicted plants and animals have a peculiar story about how or why they were first collected."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Back",
            slug: "back",
            year: "2003",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "The plants and animals in this picture went to the brink of extinction but came back. Some were carefully nurtured into survival by various levels of human intervention, including habitat manipulation, captive breeding, and reintroduction. Others were presumed extinct and then re-found, by accident or design. Such a species lucky enough to return from the edge of oblivion is sometimes referred to as ‘Lazarus taxon’"
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Gone",
            slug: "gone",
            year: "2004",
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 36”",
            description: "The sixty-three species painted in GONE have all become extinct since the mid 1800’s and the colonization of the New World. The current high rate of extinction is humanity’s doing; we’re the ones who disrupt and degrade habitat, alter soil and water, and warm the atmosphere. Of the sixty-four species of birds endemic to Hawaii, thirty-three have gone extinct: island species are particularly vulnerable and many are represented here. Where ever humans migrated around the world, edible fauna went extinct. Extinction is thus far not reversible."
        }]
    },
    {
        order: 3,
        hero: 1,
        name: 'Outdoor Still Life',
        slug: 'outdoor-still-life',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Nantahala",
            slug: "nantahala", 
            year: "2023",
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "36” x 48”",
            description: "All of the species in this painting were photographed in the Joyce Kilmer Memorial Forest of the Nantahala National Forest, in the Smoky Mountains near the Tennessee/North Carolina border, in Graham County, NC. “Nantahala” means “land of the noon day sun” in Cherokee: the mountains are so steep and close together that the sun only reaches some of the valleys at noon. The hills of this region are still deeply wooded and most roads are not paved.  The Smoky Mountains have the highest number of salamander species in the world; two of them are depicted here.<br/><br/>The central panel shows each of the species I photographed at life size and then each  is shown in close up in one of the surrounding boxes magnified greatly.  The inspiration for this painting, and what I hope will be an ongoing series, was the reprinting of a book called Werner’s Colors. This book was a color system for natural historians of the 19th century so that all could agree on a common color language: the original pantone. This painting was also meant to be an exploration of how color is carried on the surface of living organisms, using a small hand held microscope in the field. Further experiments forthcoming in future.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Father Time",
            slug: "father-time",
            year: "2022",  
            medium: "Oil on polyester over panel",
            dimensions: "60” x 48”",
            description: "“Father Time” depicts a sampling of animals where male parents do a big share of their babies’ care. Whether they build nests, secure provisions, carry the infants, or raise the young from egg to near adult, the males in this painting are present for their off-spring sometimes, and in the case of the southern cassowary, the sole caretaker. While we are familiar with male birds’ assistance in spring with nest materials, territory defense, and feeding, it is interesting to note how many amphibian, fish and insect dads are engaged in the survival of their young. With all of the animals on earth, one wonders why so few fathers are involved with their children."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Co-Existants",
            slug: "co-existants",
            year: "2017",
            medium: "Oil on polyester over panel",
            dimensions: "44” x 64”",
            description: "This picture was commissioned by the Art in Embassies program of the U.S. State Department for display in its embassy building in Paramaribo. Isabella Kirkland spent three weeks in Suriname in the spring of 2016. During those weeks she consulted with Conservation International, Green Heritage and faculty at Anton de Kom University to build a list of species representative of the country’s flora and fauna and she attempted to see as many of those species as possible. Everything in this painting is presented at half life-size. <br/><br/>The painting was executed in oil using the most stable and light fast pigments currently known. Co-Existants is part of Kirkland’s series of images documenting plants and animals that may not survive the Sixth Mass Extinction: the Age of Man, now being called the Anthropocene. This picture, if it is lucky to survive itself, offers a permanent, analog, visual record celebrating some of Suriname’s remarkable biota. <br/><br/>Suriname has three UNESCO World Heritage sites. One includes 16,000 miles of primary rainforest, one of the largest on the planet. Suriname’s great species diversity is matched by wide ranging cultural diversity. The title “Co-Existants” honors the grace with which the myriad peoples of Suriname, for the most part, get along; and it echoes the “Peaceable Kingdom” quality of the composition.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Palisades",
            slug: "palisades",	
            year: "2012", 
            medium: "Oil on polyester over panel",
            dimensions: "48” x 36”",
            description: "The image of a meadow, north of New York City, across the Hudson River from the Palisades, shows what may once have lived there along with some plants and animals that still may. We forget, when we look out over a plot of land, how it once appeared: a place like this one probably had 10? 15? 20? times the species richness we now see. This concept, that it is hard to register loss over time, is called shifting baselines. Imagine how the mighty Hudson looked before Europeans first sailed up its waters, waters that teemed with fish and waterfowl of great variety and abundance.  John James Audubon was devastated by the deforestation of America he witnessed in the second half of the 19th century.  While much of New England has returned to forest, it is a depleted version of its previous incarnation, and lacks the great diversity it will take centuries to renew.  We must become better stewards of our own future and incorporate, even incentivize, a longer view of appropriate land use.",
        }]														
    },
    {
        order: 4,
        hero: 8,
        name: "Gravestone Lichens",
        slug: "gravestone-lichens",
        date: '2018-19',
        description: "What we call lichens are made of both algae and fungi in a symbiotic relationship. Lichens are excellent monitors of air quality since they have no roots but get all of their nutrients from sugars made by the photosynthesis of the algae and the atmosphere itself. Too much nitrogen can kill the algae's chlorophyll. The lichens in this series were all growing on stones in the Old Westside Cemetery in Edgartown on Martha's Vineyard. That cemetery was founded in pre-Revolutionary times, 350+ years ago, allowing time for a rich diversity of lichens to grow. Many cemeteries pressure wash headstones which kills these free air quality monitors.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 5000,    
            name: "Gravestone Lichens I",
                slug: "gravestone-lichens-i",
                year: "2017", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens II",
                slug: "gravestone-lichens-ii",
                year: "2017", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens III",
                slug: "gravestone-lichens-iii",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens IV",
                slug: "gravestone-lichens-iv",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens V",
                slug: "gravestone-lichens-v",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VI",
                slug: "gravestone-lichens-vi",
                year: "2018", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VII",
                slug: "gravestone-lichens-vii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens VIII",
                slug: "gravestone-lichens-viii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            },
            {
                visible: true,
                colouringPage: false,
                originalSize: 5000,
                name: "Gravestone Lichens IX",
                slug: "gravestone-lichens-viiii",
                year: "2019", 
                medium: "Oil & alkyd on wood", 
                dimensions: "8” x 8”",
                description: "",
            }
        ]
    },
    {
        order: 5,
        hero: 8,
        name: 'Butterflies of ABRI',
        slug: 'butterflies-of-abri',
        date: '2018-19',
        description: "The African Butterfly Institute in Nairobi, Kenya holds over 4 million specimens, about 1 million of which are pinned. ABRI has the most complete collection of African lepidoptera anywhere in the world, representing 98% of all African species and much of Madagascar's diversity as well. These paintings were made to help fund ABRI's future and to raise awareness of this remarkable repository of the continent's butterflies.  This collection is the entire life's work of 30-40 people. At present ABRI resides on private land and has no institutional home, so its future is an open question. <a href='https://www.lepsocafrica.org/?p=activities&s=regions'>https://www.lepsocafrica.org/?p=activities&s=regions</a>",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Kalima jacksoni',	
            slug: 'kalima-jacksoni',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Pseudactraea boisduvali',	
            slug: 'pseudactraea-boisduvali',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: 'Papilio zalmoxis',	
            slug: 'papilio-zalmoxis',	
            year: '2019',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: 'Junonia',	
            slug: 'junonia',	
            year: '2019',
            dimensions: "16” x 18”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3600,
            name: 'Protogoniomorpha parhassus',	
            slug: 'protogoniomorpha-parhassus',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Seasonal Dimorphism (Precis octavia)',	
            slug: 'seasonal-dimorphism',	
            year: '2020',
            dimensions: "12” x 16”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: 'Mixed Box with Uraniids',	
            slug: 'mixed-box-uraniids',	
            year: '2020',
            dimensions: "16” x 20”",
            medium: 'Oil & alkyd on panel',
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: 'Mutant Papilio dardanus',	
            slug: 'mutant-papilio-dardanus',	
            year: '2019',
            dimensions: "12” x 12”",
            medium: 'Oil & alkyd on panel',
            description: "",
        }]
    },
    {
        order: 6,
        hero: 7,
        name: "Oceans",
        slug: "oceans",
        date: '2020-ongoing',
        description: "Oceans cover more than 70% of our planet's surface. As Sylvia Earle says, \"No blue, no green.\" Without oceans, there would be no life here on Earth. The oceans are our last great frontier. The paintings in this section highlight just a few curious saltwater denizens. Thanks to the many biologists, divers, and oceanographers that have allowed me to use their photographs for reference and accuracy.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Squat Lobsters",	 
            slug: "squat-lobsters",	 
            year: "2021", 
            medium: "Oil & alkyd on wood panel",
            dimensions: "20”x20”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Brittle Star Aggregation",
            slug: "brittle-star-aggregation",
            year: "2021",
            medium: "Oil & alkyd on polyester over panel.",	
            dimensions: "36” x 36”", 
            description: "All the organisms in this picture are eaten by humans in some part of the world.  They are all depicted at life-size so that they are in the correct relative scale. There are about 2000 species of edible insects worldwide. <br/><br/>To understand more on this subject read about Brian Fisher’s BREAKFAST BEFORE CONSERVATION program here: <a href=\"https://www.ipsio.org/valala-farms.html\" target=\"_blank\">https://www.ipsio.org/valala-farms.html</a>. There is an interesting article about his work in Madagascar and beyond here: <a href=\"https://time.com/5942290/eat-insects-save-planet/\" target=\"_blank\">https://time.com/5942290/eat-insects-save-planet/</a>. <br/><br/>To help support that ongoing research please visit the California Academy of Sciences: <a href=\"https://give.calacademy.org/campaign/breakfast-before-conservation/c291684\" target=\"_blank\">https://give.calacademy.org/campaign/breakfast-before-conservation/c291684</a>.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Empty Aegean",	
            slug: "empty-aegean",	
            year: "2016", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "<i>Glaucus atlanticus</i>, also called the blue sea dragon, floats upside down just under the water's surface of the tropical and subtropical oceans of the world. This nudibranch eats Portuguese man-o-war and other organisms that sting. It can save the stinging cells and redeploy them for its own defense. Beware, these dragons can give a human a powerful sting.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Bubbles",	
            slug: "bubbles",	
            year: "2017",
            medium: "Oil & alkyd on wood", 
            dimensions: "14” x 11”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Trobriand Dawn",
            slug: "trobriand-dawn",
            year: "2015",
            medium: "Oil on polyester over panel",
            dimensions: "48” x 60”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Nudibranchia",
            slug: "nudibranchia",	
            year: "2014", 
            medium: "Oil & alkyd on canvas over panel",
            dimensions: "4' x 5'",
            description: "Nudibranchia is a celebration of the fantastic variety of physical and behavioral traits that have evolved in this order of animals. Nudibranchs are mollusks without shells: “nudi” = naked and “branch” = gill. The 206 species here are just a small portion of those known: estimates of the total number of species run from 3,000 to 6,000. Given that 7/10’s of Earth’s total area is ocean, how short a time we humans have been able to physically explore under-water, and how little of our marine world has actually been surveyed, who knows? The oceans are the last great frontier on the planet and nudibranchs inhabit every part of every ocean: from shallow reefs and mudflats to the depth of a thermal vent, from the Arctic to the equator. These creatures are short-lived. Many have only a few days to a few months to reproduce once they reach sexual maturity. In order to maximize their chances to mate, all are hermaphrodites. They can also mate across generations, in what is called protandry (reproduction with your grandfather or great niece): any sexually mature nudibranch can mate with any other mature one of its species, regardless of size. A wide array of mimicries and camouflages has also evolved among nudibranchs. Some nudibranchs look so much like their food species they are virtually invisible if nestled amongst them. Many can swim. All nudibranchs have only the most rudimentary vision and see no color at all. <a href='https://en.seaslug.world/'>https://en.seaslug.world/</a>",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "One Species",	
            slug: "one-species",	
            year: "2014", 
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "Sea slugs use a variety of means to defend themselves from predators. Most of the Armina species, like that in the upper left, bury themselves for a portion of the day or night to hide. To the right of that Cerberilla affinis has shed some of its cerata (those projections on its back), rather the way a lizard can shed its tail, giving the animal a chance to get away. Plocamopherus imperialis, at middle left, flashes a startling bluish light if it is disturbed. Jorunna funebris, at middle right, is covered with glass-like spicules…any fish biting it will get a mouth full of stickers. Many of the Dorid slugs like this Glossodoris cincta can emit foul tasting chemicals from pores along the edge of their “skirt” or mantle.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Appendages",	
            slug: "appendages",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "The left half of this painting shows the range of structures nudibranchs use for sensing their environment. These earlike stalks are called rhinophores, suggesting their relationship to noses. Most rhinophores can be drawn back into the body when danger is sensed. Their world is defined by chemistry: sea slugs are virtually blind, though some have patches of cells able to detect basic light and dark. Nudibranchs are basically blind to color perception as far as we can tell ….. all of this wild color is not for each other. The right half of the painting represents the variety of cerata: worm, acorn, leaf, or blade like projections from many slugs’ backs. Cerata are used for oxygen exchange: species with cerata do not have gills on their butts, (affectionately called “butt lettuce”) and vice versa. In species that willfully eject cerata under duress, some of those cerata wiggle on their own once ejected, adding to an attacker’s confusion, possibly allowing the slug to escape. The more blade-shaped cerata function as housing units, hosting symbiotic photosynthesizing algae, called zooxanthellae. Host nudibranchs will orient their cerata perpendicularly to incoming sunlight, this maximizes their zooxanthellae’s production of sugars, which both host and hosted will consume. ",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Self Defense",	
            slug: "self-defense",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "Sea slugs use a variety of means to defend themselves from predators. Most of the Armina species, like that in the upper left, bury themselves for a portion of the day or night to hide. To the right of that Cerberilla affinis has shed some of its cerata (those projections on its back), rather the way a lizard can shed its tail, giving the animal a chance to get away. Plocamopherus imperialis, at middle left, flashes a startling bluish light if it is disturbed. , Jorunna funebris, at middle right, is covered with glass-like spicules…any fish biting it will get a mouth full of stickers. Many of the Dorid slugs like this Glossodoris cincta can emit foul tasting chemicals from pores along the edge of their “skirt” or mantle. The slug at the bottom right has a way to use the stinging cells, or nematocysts, from their prey. Flabeliina exoptata and other Aeolids consume nematocysts when eating corals, anenomes and hydroids. They do not digest the stinging cells but store them in the tips of their cerata and deploy them against their own would-be predators. Stored nematocysts must be refreshed every 5 days.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Egg Masses",	
            slug: "egg-masses",	
            year: "2014",
            medium: "Oil & alkyd on panel", 
            dimensions: "8” x 8”",
            description: "Looking across from upper left to lower right, these are the egg cases of nudibranchs. Each species lays a unique type of egg case. The individual eggs vary in size. Some species’ eggs hatch into miniature versions of the adult nudibranch that immediately settle down to life. Other species’ eggs hatch into a minute shelled stage, called a verliger. Verligers are small enough to be suspended in the water column. They geographically disperse on ocean currents before growing enough to settle down, literally, and transform into fully functioning adults. • Hexabranchus sanguineus • Navanax inermis • Hypselodoris picta • Bonisa nakaza • Onchidoris depressa • Hermissenda crassicornis • Marionia hawaiiensis • Chromodoris annae • Pleurolidia juliae • Janolus fuscus • Melibe megacerus • Leminda millecra • Unknown Melibe? • Unknown • Possibly Doto amyra • Aegires pruvotfolae",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Appendages II",
            slug: "appendages-ii",
            year: "2022",  
            medium: "Oil on panel",
            dimensions: "10” x20”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Dumbo",
            slug: "dumbo",
            year: "2015",
            medium: "Oil on panel",
            dimensions: "8” in diameter",
            description: ""
        }]
    },
    {
        order: 7,
        hero: 1,
        name: 'Birds',
        slug: 'birds',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Paradise Parrots",
            slug: "paradise-parrots",	
            year: "2015", 
            medium: "Oil on panel",
            dimensions: "15.75” x 19.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3500,
            name: "Well Studied",
            slug: "well-studied",	
            year: "2018", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Ivory-billed Woodpeckers",
            slug: "ivory-billed-woodpecker",	
            year: "2005", 
            medium: "Oil on panel", 
            dimensions: "20” x 16” & 10.75” x 13.75”",
            description: "This picture was created in 2005, when a story broke in the New York Times about the possible sighting of an ivory-billed woodpecker in the Big Woods region of eastern Arkansas. Woodpeckers forage for food and nest in holes in standing deadwood. In the past, forest managers have tended to remove dead, fallen or snagged trees in hopes of suppressing wildfires. Although sadly the sighting seems to be erroneous, this picture still represents our sincere hope that nature is more resilient than it appears.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Bachman's Warbler",
            slug: "bachmans-warbler",	
            year: "2011", 
            medium: "Oil on panel", 
            dimensions: "15.75” x 19.75”",
            description: "<i>Vermivora bachmani</i> was first recorded by Reverend John Bachman who sent skins to John J. Audubon. Audubon described the bird as a species in honor of Bachman in 1833. Its last confirmed sightings were in the 1960's and it is presumed extinct, despite several unconfirmed or mistaken sightings in this century. The vast swathes of cane that used to cover and protect the low lying coasts of the southeastern states of the US have disappeared, along with this and other species that relied on this habitat.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5500,
            name: "Bachman's Warbler Redux",
            slug: "bachmans-warblers-redux",	
            year: "2018", 
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Akialoa",
            slug: "akialoa",
            year: "2011",
            medium: "Oil on panel",
            dimensions: "18” x 20”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Great Auk Egg",
            slug: "great-auk-eggs",
            year: "2011",
            medium: "Oil on panel",	
            dimensions: "12”x 6”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Great Auk Egg 2",
            slug: "great-auk-eggs2-tn",
            year: "2018",
            medium: "Oil on panel",	
            dimensions: "12”x 6”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "I'iwi",
            slug: "iiwi",
            year: "2011",
            medium: "Oil on panel",
            dimensions: "7.75” x 9.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Varied Thrush",
            slug: "varied-thrush",
            year: "1994",
            medium: "Oil on panel",
            dimensions: "6” x 11-7/8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Cadmium jay",
            slug: "cadmium-jay",
            year: "1994",   
            medium: "Oil & powdered cadmium on panel",
            dimensions: "16.5” x 13.25”",
            description: "",
        }]
    },
    {
        order: 8,
        hero: 1,
        name: 'Insects, Bugs & Spiders',
        slug: 'insects-and-bugs',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Spider Foot",
            slug: "spider-foot",
            year: "2021", 
            dimensions: "10” x 10”", 
            medium: "Oil & alkyd on panel",
            description: "Thanks to Wim van Egmond for letting me make this painting from his photograph, taken through a microscope, of a typical European garden spider’s foot  (Araneus diadematus). You can clearly see from this image how well a spider can read its own web."
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Edibles",
            slug: "edibles",	
            year: "2021", 
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "36” x 48”",
            description: "Every creature in this painting is eaten somewhere in the world by humans, in one or more of its several forms: egg, larva, pupae, or adult. And people have done so for millennia. There may be as many as 2,000 species of insects that are eaten, but the three most common are grasshoppers, crickets, and mealworms. Insects are high in protein and are significantly higher sources of minerals such as iron, zinc, copper, and magnesium than beef; however, they require much less water, land, and feed to raise. About 80% of the world’s farmland is used to raise animal protein, though that protein provides only about 18% of global calorie consumption. While the idea of eating insects is repugnant to most western people, we race towards a population of 9 million humans. The strain to produce enough beef and pork to satisfy this many of us is untenable. Insect protein powder may help relieve some of this strain on world resources.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Membracidae",
            slug: "membracidae",	
            year: "2021",   
            medium: "Oil & alkyd on polyester over panel",
            dimensions: "60” x 48”",
            description: "While found on every continent except Antarctica, these sap-sucker insects live mostly in tropical regions of the world.  Treehoppers, members of the family Membracidae, are known for their ornately shaped second body segment, or pronotum. The bizarre shapes of these adaptations make treehoppers resemble buds and thorns, perhaps as camouflage or, as mimicry for wasps and ants.  In fact, many species of treehoppers have a mutualistic relationship between both ants, wasps, and even some geckos.  Treehoppers communicate with each other and with other species by vibrations produced in their abdomens through the plants on which they live. Most of the interrelationships with other species are based on an exchange of protection for honeydew the treehoppers produce from plant sap. For such tiny, short-lived creatures, only a few months for most individuals, some of these mutualistic arrangements are remarkably complex. Treehoppers are, for the most part, innocuous to humans."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Famous Bugs",
            slug: "famous-bugs",
            year: "2012",
            medium: "Oil on panel",
            dimensions: "19.75” x 15.75”",
            description: "This picture includes three new species of slime mold beetles named for Bush, Cheney, and Rumsfeld. The slime mold beetles <i>Agathidium bushi</i>, <i>Agathidium cheneyi</i>, and <i>Agathidium rumsfeldi</i> are all very small in scale. <i>Agra eowilsoni</i>, <i>Eristalis alleni</i>, <i>Eristalis gatesi</i>, <i>Cyclocephala Casanova</i>, <i>Lycaeides idas nobokovi</i>, and <i>Dalailama vadim</i> are named after prominent people whose names you might recognize. Some new names are honorific, some are in thanks for support, and some are bestowed because of appearance.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Old Opportunists",
            slug: "old-opportunist",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "20” x 16”",
            description: "The beetles crawling around this traditional still life are Japanese beetles (<i>Popillia japonica</i>); they  arrived in the US from Japan in 1916. In Japan they have natural predators, but in their adopted home, where they do not, their numbers have soared and they are now considered pests. In keeping with the subject of an \"old\" invader, the picture references Dutch still life tropes like a pewter plate and pitcher and adds to the suggestion of antiquity with an early American quilt."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "New Opportunists",
            slug: "new-opportunist",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "16” x 20”",
            description: "Visually hidden in this more modern style flower painting are spotted lanternflies, <i>Lycorma delicatula</i>. These planthoppers native to Asia were first found in the US in 2014 in Pennsylvania.(These insects are hyper-aware and will scuttle around the other side of a tree to avoid observation or a camera lens.) Since 2014 they have spread to Connecticut, Delaware, Indiana, Massachusetts, New Jersey, New York, North Carolina, Ohio, Pennsylvania, Virginia, West Virginia, and Maryland, despite some extreme measures taken against their proliferation."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Morpho Chrysalises",
            slug: "morpho-chrysalises",
            year: "2023",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Heliconius Chrysalises",
            slug: "heliconius-chrysalis",
            year: "2023",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Mpala Walking Stick",
            slug: "kenyan-walking-stick",
            year: "2020",
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Super Green I",
            slug: "super-green-i",
            year: "2020", 
            medium: "Oil & alkyd on panel",
            dimensions: "24” x 18”", 
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Super Green II",
            slug: "super-green-ii",
            year: "2021",
            medium: "Oil & alkyd on panel",
            dimensions: "18” x 24”", 
            description: ""
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Butterfly Chrysalises - 3",
            slug: "butterfly-chrysalises-3",
            year: "",
            medium: "",
            dimensions: "",
            description: ""
        },
        {
        }]
    },
    {
        order: 9,
        hero: 3,
        name: 'Plants',
        slug: 'plants',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Leucistic Redwood",	
            slug: "leucistic-redwood",	
            year: "2019",
            medium: "Oil on panel",
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Cereus",
            slug: "cereus",
            year: "",
            medium: "",
            dimensions: "",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Leaf",
            slug: "leaf",
            year: "2013",
            dimensions: "14” x 10.5”",
            medium: "Oil on panel",
            description: ""
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Night-blooming Cereus",
            slug: "night-blooming-cereus",
            year: "",
            dimensions: "",
            medium: "Oil on panel",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Passiflora tarminiana 1",
            slug: "passiflora-tarminiana-i",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "13.75 x 10.75",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Passiflora tarminiana 2",
            slug: "passiflora-tarminiana-ii",
            year: "2005",
            medium: "Oil on panel", 
            dimensions: "13.75 x 10.75",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Passiflora gabrielliana",
            slug: "passiflora-gabrielliana",
            year: "2005",
            medium: "Oil & alkyd on wood",
            dimensions: "10.75” x 13.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Blakea scarlatina",
            slug: "blakea-scarlatina",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "13.75” x 10.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Mutisia magnifica",
            slug: "mutisia-magnifica",
            year: "2005",
            medium: "Oil on panel", 
            dimensions: "13.75” x 10.75”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Spring Rain",
            slug: "spring-rain",
            year: "2019",
            medium: "Oil on panel", 
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Magnolia",
            slug: "magnolia",
            year: "2013",
            medium: "Oil on panel", 
            dimensions: "8” x 10”",
            description: "",
        }]
    },
    {
        order: 10,
        hero: 2,
        name: 'Genetics',
        slug: 'genetics',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Dolly's Perfectly Healthy CoClones",
            slug: "dolly",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "16” x 20”",
            description: "Dolly made a lot of headlines when she was born: she was the first animal cloned from adult sheep cells. And there was some noise and worry when she developed age-related health issues early, at around age five. However, Dolly was just one of 13 cells cloned at the same time and from the same cell line. This painting is of four of her surviving \"cell mates\". Her co-clones have lived normal lives outside of the spotlight with no signs of early senescence.",
        }, 
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Transgenic Mice",
            slug: "transgenic-mice",
            year: "2018",
            medium: "Oil on panel",
            dimensions: "12” x 16”",
            description: "",
        }, 
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Astraptes Complex",
            slug: "astraptes-complex",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "36” x 30”",
            description: "This piece is in honor of the millions of hours humans have invested to describe the nature that surrounds us, with just the basic tools shown and a lot of attention to detail. This picture is also about an idea at the time proposed by Dr. Daniel Jantzen to use a sample of DNA as a new \"barcode\" of life. The caterpillars in the foliage are a prime example of why this new barcode would be a transformative new tool in taxonomy: Jantzen and his team reared each of these caterpillars and every one of them appeared to be the same species of adult, <i>Astraptes fulgerator</i>.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Bio-luminescent Mice",
            slug: "bio-mice",
            year: "2005",
            medium: "Oil on panel",
            dimensions: "10.75” x 13.75”",
            description: "",
        }]
    },
    {
        order: 11,
        hero: 3,
        name: 'Phasmid Eggs',
        slug: 'phasmid-eggs',
        date: '2021',
        description: "Phasmid is shorthand for the walking sticks and leaf insects in the order Phasmatodea.  In Ancient Greek “phasma” meant ghost or phantom: these insects are very cryptic and blend in extremely well with branches and foliage.  Most Phasmid eggs are between three and 30 millimeters in length.  These eggs are not painted to scale here to emphasize the profound diversity of their structures and textures.  Many of these species have a small cap made of fats and proteins, very attractive to ants. An ant will pick up an egg, carry the egg into its nest, eat the cap, and forget the egg.  The egg then gets to develop in the nest, safe from harm and at a constant temperature and humidity. Once it hatches, the tiny insect leaves the nest, and goes on with its life. Each egg illustrated here, tells the tale of its deposition: some eggs are scattered at random, some are taken by ants, and some are carefully skewered through a leaf to hang safely hidden in that leaf’s shadow. Learn more at the <a href='https://phasmidstudygroup.org/'>Phasmid Study Group</a>.",
        paintings: [{
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Phasmid Eggs 1",	
            slug: "phasmid-eggs-1",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 60”",
            description: "Phasmid is shorthand for Phasmatodea, the order of walking sticks and leaf insects. In Ancient Greek “phasma” meant ghost or phantom: these insects are very cryptic, blending in extremely well with branches and foliage. Most of the eggs of Phasmids are between two and thirty millimeters in length. These are NOT painted to scale here, as I wanted to emphasize the profound diversity in texture and appearance by keeping them within a close size range. <br/><br/>Many of these species have a small cap on top that is made of fats and proteins, very altractive to ants. An ant will pick up the egg. carry it to its nest, eat the cap, and forget the egg. The egg then gets to develop, safe from harm, at a constant temperature and humidity. Once it hatches, it leaves the nest and begins its life. Look up “Phasmid Study Group” to learn all you would ever want to know.",
        },
        {
            visible: true,
            colouringPage: true,
            originalSize: 8000,
            name: "Phasmid Eggs 2",	
            slug: "phasmid-eggs-2",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "48” x 60”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 3",	
            slug: "phasmid-eggs-12-1",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 2",	
            slug: "phasmid-eggs-12-2",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 6000,
            name: "Phasmid Eggs 3",	
            slug: "phasmid-eggs-12-3",	
            year: "2021", 
            medium: "Oil on polyester over panel", 
            dimensions: "12” x 12”",
            description: "",
        }]
    },
    {
        order: 12,
        hero: 1,
        name: 'Diversity',
        slug: 'diversity',
        date: 'ongoing',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Bunny Meeting",
            slug: "bunny-meeting",
            year: "2010",
            medium: "Oil on panel",
            dimensions: "18” x 24”",
            description: "<i>Nesolagus timminsi</i>, or the Annamite striped rabbit, was first seen in 1996 at a market in Bak Lak, Laos. It was described in 2000. The border between Laos and Vietnam is in the Annamite mountains, an area heavily carpet bombed during the Vietnam war. Scientists did not work in that area for many years for that reason.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "By a Thread",
            slug: "thread",
            year: "2005", 
            medium: "Oil on panel", 
            dimensions: "20” x 16”",
            description: "<i>Sicyos alba</i> (or <i>Sicyos albus</i>) is a plant in the cucumber family that lives only in Hawaii where it is threatened by wild pigs and habitat loss. <i>Aepypodius bruijnii</i> is an endangered megapode from the island of West Papua in Indonesia. Threats to its continued existence are hunting and habitat loss, though its population may have always been restricted geographically.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Chodsigoa caovansunga",
            slug: "chodsigoa",
            year: "2005",
            medium: "Oil on panel",	
            dimensions: "3” x 5”",
            description: "Van Sung's shrew, Chodsigoa caovansunga and Huernia thuretii.",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Diptych: The Quick and the Dead",	
            slug: "quick-dead",
            year: "1997",
            medium: "Oil on panel", 
            dimensions: "",
            description: "These two paintings together ask a question: How do we decide how much time and money to allocate to which problems or causes? The Quick shows an American Kestrel, <i>Falco sparverius</i>, superimposed over an image taken from the news of the Million Man March at the US Capitol in Washington, DC. The Dead shows another event at the US Capitol, again taken from the news: the last time the entire AIDS quilt was in one place. The Dead has a pair of extinct male passenger pigeons, <i>Ectopistes migratorius</i>, superimposed over it. Both subjects are worthy of our time, attention, and support, but how can each be assigned a numeric value?",	
        }]
    },
    {
        order: 13,
        hero: 5,
        name: 'Nature in the Margins',
        slug: 'nature-in-the-margins',
        date: '1995-1999',
        description: "These paintings were done in the late 90's. They each show some species which live in a remnant habitat, a small corner of nature we have unintentionally left for them. The paintings were used to experiment with a variety of painting techniques including those of the still life masters of 17th C Netherlands",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Hubble Space",
            slug: "hubble",
            year: "1997",
            medium: "Oil on masonite",
            dimensions: "24” x 18”",
            description: "The white-footed or wood mouse, <i>Peromyscus leucopus</i>, is the second most used lab mouse after the house mouse. So, it is very common, and here, it stands for each one of us, so small against the infinity of space as seen through the lens of the Hubble telescope.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Mass Transit",
            slug: "mass-transit",
            year: "1996",
            medium: "Oil on panel",
            dimensions: "14” x 11”",
            description: "Great egret, Ardea alba."
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "Mall of America",
            slug: "mall-america",
            year: "1996",
            medium: "Oil on true gesso on masonite", 
            dimensions: "11” x 14”",
            description: "Least terns, Sternula antillarum, sometimes nest on mall roofs since access to their normal nest sites, beaches, dunes, and riverside banks, have been usurped, reduced, or soiled by human activity. Mall roof nests cause high chick mortality.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Shuttle",
            slug: "shuttle",
            year: "1995", 
            medium: "Oil on true gesso on masonite", 
            dimensions: "7” x 9”",
            description: "Red-winged blackbird, Agelaius phoeniceus.",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Unnatural Angle: In Heaven there Is No Right Wing",
            slug: "in-heaven",
            year: "1996", 
            medium: "Oil on true gesso on masonite", 
            dimensions: "13.5” x 16.75”",
            description: ""
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Mission Blue",
            slug: "mission-blue",
            year: "1996", 
            medium: "Oil on masonite", 
            dimensions: "8” x 10”",
            description: "Mission blue butterfly, <i>Icaricia icarioides missionensis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Love Nest",
            slug: "love-nest",
            year: "1997",
            medium: "Oil on masonite", 
            dimensions: "17” x 21”",
            description: "Satin bowerbird, <i>Ptilonorhynchus violaceus</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Where Will the Blue-tailed Mole Skinks Live?",
            slug: "where-will-mole",
            year: "1996",
            medium: "Oil on masonite", 
            dimensions: "20” x 10”",
            description: "Blue-tailed mole skink, <i>Eumeces egregius lividus</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Rarest Mammal in the Lower 48",
            slug: "rarest-mammal",
            year: "1996",
            medium: "Oil on masonite", 
            dimensions: "24” x 12”",
            description: "Spotted bat, <i>Euderma maculatum</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 5000,
            name: "Painted Beauty (Cynthia virginiensis)",
            slug: "painted-lady",
            year: "1995",
            medium: "Oil on masonite",
            dimensions: "4” x 4”",
            description: "Painted lady butterfly, <i>Cynthia virginiensis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Auk Egg and Key",
            slug: "auk-egg-key",
            year: "2003",
            medium: "Oil on masonite",
            dimensions: "14” x 11”",
            description: "Great auk, <i>Pinguinus impennis</i>"
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Auk Egg and Least Blue (Maculinea arion)",
            slug: "auk-egg-leastblue",
            year: "2003",
            medium: "Oil on masonite",
            dimensions: "14” x 11”",
            description: "Great auk, <i>Pinguinus impennis</i> Least blue butterfly, <i>Maculinea arion</i>"
        }]
    },
    {
        order: 14,
        hero: 1,
        name: 'Luck Suite',
        slug: 'luck-suite',
        date: '2017-ongoing',
        description: "This set of works incorporate pressed and dried four-leafed clovers as marks and as mnemonic devices. These abstract, sculptural paintings are about the nature of luck and how we think of it.",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 7000,
            name: "123 Lucky Mutants",
            slug: "123-lucky-mutants",
            year: "2017",
            medium: "4-leafed clovers & oil and alkyd on polyester over panel",
            dimensions: "3” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Breathe",
            slug: "breathe",
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Circulation",
            slug: "circulate",
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Device for the Prevention of Armageddon",	
            slug: "device-armageddon",	
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "36” x 36”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Hard Luck",
            slug: "hard-luck",
            year: "2020",
            medium: "Oil, clovers, & graphite on panel",
            dimensions: "48” x 36”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Hex",	
            slug: "hex",	
            year: "2019",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 18”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Morph-Luck",
            slug: "morph-luck",
            year: "2018",
            medium: "4-leafed clovers & oil and alkyd on wood",
            dimensions: "9” x 9”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Nine Patch",	
            slug: "nine-patch",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "18” x 24”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 8000,
            name: "Oceanic",	
            slug: "oceanic",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "24” x 28.5”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Radiate",	
            slug: "radiate",	
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "12” x 12”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 6000,
            name: "Split",
            slug: "split",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "12” x 14”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 1",
            slug: "countdown-1",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 2",
            slug: "countdown-2",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 3",
            slug: "countdown-3",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 4",
            slug: "countdown-4",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 5",
            slug: "countdown-5",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 6",
            slug: "countdown-6",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Countdown 7",
            slug: "countdown-7",
            year: "2020",
            medium: "Oil & clovers on panel",
            dimensions: "4” x 4”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Isolate, Sequester, Quarantine",	
            slug: "isolate",	
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Pandemic",
            slug: "pandemic",
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Survival",
            slug: "survival",
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",	
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "Covid Clovers - Neighbors",	
            slug: "neighbors",	
            year: "2020",
            medium: "Clovers on arches paper",
            dimensions: "22.5” x 30”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 3000,
            name: "We Were So Lucky",
            slug: "so-lucky",
            year: "2014",
            medium: "4-leaf clovers & oil and alkyd on wood",
            dimensions: "15.5” x 24”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "25 Years",
            slug: "25-years",
            year: "",
            medium: "",
            dimensions: "20” x 16”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Lead",
            slug: "lead",
            year: "",
            medium: "",
            dimensions: "10” x 10”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Quilt",
            slug: "quilt",
            year: "",
            medium: "",
            dimensions: "18” x 24”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Raspberry",
            slug: "raspberry",
            year: "",
            medium: "",
            dimensions: "9” x 12”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "Pie squared",
            slug: "pie-squared",
            year: "",
            medium: "",
            dimensions: "8” x 8”",
            description: "",
        },
        {
            visible: false,
            colouringPage: false,
            originalSize: 8000,
            name: "We are All Unique",
            slug: "we-are-all-unique",
            year: "",
            medium: "Oil on panel",
            dimensions: "12” x 12”",
            description: "",
        }]
    },
    {
        order: 15,
        hero: 1,
        name: 'Pencil Suite',
        slug: 'pencil-suite',
        date: '',
        description: "",
        paintings: [{
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "My Hands",
            slug: "my-hands",
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Old Colebus",	
            slug: "old-colebus",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Dreamscape",	
            slug: "dreamscape",	
            year: "2022",
            medium: "Graphite on Arches paper",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Dry Season Shadow",	
            slug: "dry-season",	
            year: "2022",
            medium: "watercolor on Arches paper",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Weighted line drawing",	
            slug: "weighted-line",	
            year: "2022",
            medium: "Graphite on Arches paper",
            dimensions: "48” x 36”" ,
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Vine",	
            slug: "kenyan-vine",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Thorns",	
            slug: "kenyan-thorn",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Lichen",	
            slug: "kenyan-lichen",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Brush",	
            slug: "kenyan-brush",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        },
        {
            visible: true,
            colouringPage: false,
            originalSize: 4000,
            name: "Kenyan Acacia",	
            slug: "kenyan-acacia",	
            year: "2022",
            medium: "Graphite on Arches",
            dimensions: "7” x 10.25”",
            description: "",
        }]
    }]
}