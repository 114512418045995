<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import PaintingViewer from '../components/PaintingViewer.vue'
import ImageWithContent from '../components/ImageWithContent.vue'

import paintingsData from '../data/paintings';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'PaintingPage',
    metaInfo() {
        const title = this.painting ? this.painting.name + ' – Isabella Kirkland' : 'Isabella Kirkland';
        
        const description = this.painting && this.painting.description ? this.painting.description : false;

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title, description);
    },
    data: function() {
        return {
            viewAll: false
        };
    },
  components: {
    GlobalHeader,
    GlobalFooter,
    PaintingViewer,
    ImageWithContent
  },
  computed: {
        'collection': function() {
            return Array.from(paintingsData.categories).find(collection => collection.slug === this.$route.params.collection);
        },
        'painting': function() {
            return Array.from(this.collection.paintings).find(painting => painting.slug === this.$route.params.painting);
        },
        'collectionPaintings': function () {
            const otherPaintings = this.collection.paintings.filter(painting => painting.visible && painting.slug !== this.painting.slug);
            return this.viewAll ? otherPaintings : otherPaintings.slice(0, 3);
        }
  },
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <PaintingViewer :collection="collection" :painting="painting" :key="$route.query._r" />
    <div class="content medium-pad custom-pad">
        <div>
            <h2 id="heading">{{ painting.name }}</h2>
            <p>{{ painting.year }}, {{ painting.medium }}, {{ painting.dimensions }}</p>
        </div>
    </div>
    <div class="content medium-pad">
        <div>
            <h3>More {{ collection.name }} Paintings</h3>
            <div class="grid--rows-of-three">
                <ImageWithContent 
                    v-for="(painting, index) in collectionPaintings"
                    :key="painting.name"
                    :index="index"
                    :image="`/painting-thumbnails/${collection.slug}/${painting.slug}-tn.jpg`"
                    :header="painting.name"
                    :caption="`${painting.year}, ${painting.medium}, ${painting.dimensions}`" 
                    :route="`/collections/${collection.slug}/paintings/${painting.slug}`"
                />
            </div>
            <button v-if="!viewAll" @click="() => { viewAll = true; }" class="button">View all</button>
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
button {
    display: block;
    margin: 0 auto;
}
.content {
    padding-top: 2em;
}
h2 {
    padding-bottom: 0;
}
</style>
