<script>
export default {
    name: 'App',
    metaInfo: {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Isabella Kirkland',
      // all titles will be injected into this template
      titleTemplate: '%s — Isabella Kirkland'
    }
}
</script>

<template>
    <metainfo>
    </metainfo>
    <router-view v-slot="{ Component }">
    <transition name="fade-page" mode="out-in">
        <component :is="Component" />
    </transition>
    </router-view>
</template>

<style scoped>
    .fade-page-enter-active,
    .fade-page-leave-active {
        opacity: 1;
        transition: all 0.2s cubic-bezier(.42,.79,.61,.35);
    }

    .fade-page-enter-from,
    .fade-page-leave-to {
        opacity: 0;
        transition: all 0.2s cubic-bezier(.42,.79,.61,.35);
    }
</style>