<template>
    <router-link :to="routeTo" class="link">
        <h2>{{ text }}</h2>
        <img class="chevron" src="@/assets/svg/chevron-right.svg"/>
    </router-link>
</template>

<script>
export default {
    name: 'InternalLink',
    props: {
        route: {
            type: String,
            required: false
        },
        path: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: true
        }
    },
    computed: {
        routeTo: function() {
            if(this.route) {
                return { name: this.route };
            }
                    
            return { 
                path: this.path,
                beforeEnter() { location.href = this.path }
            };
        }
    }
}
</script>

<style>
.link {
    margin: 2.5rem 0;
    position: relative;
    text-decoration: none;
    width: fit-content;
}
.link h2 {
    padding-right: 1.3em;
}
.chevron {
    position: absolute;
    top: 1.6em;
    right: 0;
    width: 4rem;
    transition: right 0.3s ease-out;
}
.link:hover .chevron {
    right: -0.5em; /* Adjust the value to control the slide distance */
    transition: right 1s cubic-bezier(.16,.67,.29,.87);
}
@media screen and (max-width: 768px) {
    .link {
        margin: 0rem 0;
    }
}
</style>
