<template>
    <router-link class="back-link" :class="{ 'inline': inline }" :to="{ path }">
        <img class="chevron" src="@/assets/svg/chevron-left.svg"/> Back to {{ text }}
    </router-link>
</template>

<script>
export default {
    name: 'BackTo',
    props: {
        path: {
            type: String,
            required: true
        },
        text: {
            type: String,
            required: true
        },
        inline: {
            type: Boolean,
            required: false
        },
    }
}
</script>

<style>
.back-link {
    display: block;
    padding-bottom: 2em;
    padding-left: 1.5em;
    position: relative;
    text-decoration: none;
}
.back-link.inline {
    display: inline;
}
.back-link .chevron {
    top: 1.3em;
    right: inherit;
    left: 0em;
    width: 1em;
}
.back-link.inline .chevron {
    top: 1.2em;
}
@media screen and (max-width: 640px) {
    .back-link.inline .chevron {
        top: 1.4em;
    }
}
</style>
