<template>
    <figure class="ImageWithContent">
        <template v-if="route">
            <router-link :to="{ path: route, query: { _r: Date.now() } }">
                <img v-if="image" :src="image" />
                <span class="sub-heading" v-if="subheader">{{ subheader }}</span>
                <h5 v-if="header">{{ header }}</h5>
                <p v-if="caption">
                    {{ caption }}
                </p>
            </router-link>
        </template>
        <template v-else>
            <img v-if="image" :src="image" />
            <span class="sub-heading" v-if="subheader">{{ subheader }}</span>
            <h5 v-if="header">{{ header }}</h5>
            <p v-if="caption">
                {{ caption }}
            </p>
        </template>
    </figure>
</template>

<script>
export default {
    name: 'ImageWithContent',
    props: [
        'image',
        'subheader',
        'header',
        'caption',
        'route'
    ]
}
</script>

<style scoped>
figure {
    display: grid;
    grid-template-rows: min-content min-content min-content;
    margin: 0;
}
img {
    width: 100%;
}
p {
  margin-top: 0.5rem;
  padding-top: 0;
}
a {
    text-decoration: none;
}
@media screen and (max-width: 768px) {
    p {
        margin-top: 0;
        padding-top: 0;
    }
}
</style>
