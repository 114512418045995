export default (url, title, description = false, image = false) => {
    const metaInfo = {
        title: title,
        meta: [
            { 
                name: 'description', 
                content: description
            },

            // OpenGraph
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'og:url',
                content: url,
            },
            {
                property: 'og:title',
                content: title,
            },

            // Twitter
            {
                property: 'twitter:card',
                content: 'summary_large_image',
            },
            {
                property: 'twitter:url',
                content: url,
            },
            {
                property: 'twitter:title',
                content: title,
            }
        ]
    };

    if(description !== false) {
        metaInfo.meta.push({
            property: 'og:description',
            content: description,
        });
        
        metaInfo.meta.push({
            property: 'twitter:description',
            content: description,
        });
    }

    if(image !== false) {
        metaInfo.meta.push({
            property: 'og:image',
            content: window.location.href + image,
        });

        metaInfo.meta.push({
            property: 'twitter:image',
            content: window.location.href + image,
        });
    }

    return metaInfo;
}