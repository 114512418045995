import { createApp } from 'vue'
import App from './App.vue'
import {createRouter, createWebHistory} from 'vue-router'  
import { createMetaManager } from 'vue-meta';
import { plugin as vueMetaPlugin } from 'vue-meta'
import VueGtag from "vue-gtag-next";

import routes from './routes.js';

import "@/assets/css/main.css";
import "@/assets/css/layout.css";
import "@/assets/css/typography.css";
import "@/assets/css/animations.css";

import ClickOutside from './components/directives/ClickOutsideDirective';

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.afterEach(() => {
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 20);
});

const app = createApp(App);
app.directive('click-outside', ClickOutside);
app.use(router);
app.use(createMetaManager());
app.use(vueMetaPlugin);
app.use(VueGtag, {
    property: {
      id: 'G-ZNEFB16XE5'
    }
});
app.mount('#app');

const disableRightClick = !!process.env.BASE_URL.includes('www.isabellakirkland.com');
if(disableRightClick) {
    document.addEventListener('contextmenu', event => event.preventDefault());
}