export default [
    {
        date: 'Tuesday, January 16th',
        title: "The beauty of wildlife — and an artistic call to protect it", 
        caption: "",
        link: "https://go.ted.com/isabellakirkland",
        image: 'ted-talks-placeholder.png',
        verb: 'Play'
    },
    {
        date: 'August 29, 2022',
        title: "Isabella Kirkland's Art of Nature", 
        caption: "Rising Tide #64, Inland Ocean Coalition",
        link: "https://podcasts.apple.com/us/podcast/rising-tide-64-isabella-kirklands-art-of-nature/id1501632357?i=1000577691044",
        image: 'Placeholder Image.jpg',
        verb: 'Play'
    },
    {
        date: 'September 22, 2022',
        title: "The Power of Attention...", 
        caption: "Kinute",
        link: "https://kinute.com/stories/632242534-the-power-of-attention-how-isabella-kirkland-s-work-stands-witness-to-nature-s-losses-and-gains",
        image: 'Placeholder Image-8.jpg',
        verb: 'Read'
    },
    {
        date: 'March, 2022',
        title: "Death and Taxa: Isabella Kirkland; Still Life", 
        caption: "Aeqai",
        link: "http://aeqai.com/main/2014/04/death-and-taxa-isabella-kirkland-stilled-life/",
        image: 'Placeholder Image-7.jpg',
        verb: 'Read'
    },
    {
        date: 'December 7, 2021',
        title: "Isabella Kirkland at Hosfelt Gallery", 
        caption: "The Art Show (ADAA)",
        link: "https://vimeo.com/654268636",
        image: 'Placeholder Image-1.jpg',
        verb: 'Play'
    },
    {
        date: 'June 12, 2021',
        title: "Isabella Kirkland and Greg Neimeyer in Conversation", 
        caption: "Courtesy of Hosfelt Gallery",
        link: "https://www.youtube.com/watch?app=desktop&v=QrW2bb0nrNo",
        image: 'Placeholder Image-15.jpg',
        verb: 'Play'
    },
    {
        date: 'May 10, 2021',
        title: "Renny Preitikin on Isabella Kirkland @Hosfelt", 
        caption: "Square Cylinder",
        link: "https://www.squarecylinder.com/2021/05/renny-pritikin-on-isabella-kirkland-hosfelt/",
        image: 'Placeholder Image-9.jpg',
        verb: 'Read'
    },
    {
        date: 'December 20, 2020',
        title: "Painting the Endangered World", 
        caption: "The Long Now",
        link: "https://theinterval.org/salon-talks/02016/mar/22/painting-endangered-world",
        image: 'Placeholder Image-4.jpg',
        verb: 'Play'
    },
    {
        date: 'Sept. 3, 2020',
        title: "Material Longevity", 
        caption: "UC Berkeley Arts and Design, BAMPFA",
        link: "https://www.youtube.com/watch?v=vUivYy_klto&t=9s",
        image: 'Placeholder Image-2.jpg',
        verb: 'Play'
    },
    {
        date: 'February, 2020',
        title: "A Rarified World",
        caption: "State of the Art, Crystal Bridges",
        link: "https://stateoftheart.crystalbridges.org/blog/project/isabella-kirkland/",
        image: 'Placeholder Image-3.jpg',
        verb: 'Play'
    },
    {
        date: 'September 20, 2019',
        title: "Interview 47 with Eclectix",
        caption: "",
        link: "https://medium.com/@eclectixp/isabella-kirkland-eclectix-interview-47-c6eea9b2855e",
        image: 'Placeholder Image-10.jpg',
        verb: 'Read'
    },
    {
        audio: './audio/co-existants-soundtrack/co-existants-soundtrack.mp3',
        date: 'Summer, 2017',
        title: "Co-Existants: Soundtrack",
        caption: "",
        link: "",
        image: 'co-existants-tn.jpg',
        verb: 'Play'
    },
    {
        date: 'February 9, 2015',
        title: "Panel Discussion: Science in Artistic Form", 
        caption: "Crystal Bridges",
        link: "https://www.youtube.com/watch?v=PpjHjFw38LM",
        image: 'Placeholder Image-16.jpg',
        verb: 'Play'
    },
    {
        date: 'May 18, 2014',
        title: "Dayton Art Institute", 
        caption: "",
        link: "https://www.facebook.com/daytonartinstitute/videos/artist-isabella-kirkland-speaks-about-her-painting-gone-featured-in-the-special-/10152297537597284/",
        image: 'Placeholder Image-6.jpg',
        verb: 'Play'
    },  
    {
        date: 'Summer 2013',
        title: "Still Life with Life", 
        caption: "Earth Island Journal",
        link: "https://www.earthisland.org/journal/index.php/magazine/entry/still_life_with_life/",
        image: 'Placeholder Image-11.jpg',
        verb: 'Read'
    },
    {
        date: 'April 11, 2013',
        title: "A Still Life of Stilled Life", 
        caption: "TEDx DeExtinction at National Geographic",
        link: "https://www.youtube.com/watch?v=1krVk4CMy98",
        image: 'Placeholder Image-5.jpg',
        verb: 'Play'
    },
    {
        date: 'November 8, 2013',
        title: "Stilled Life", 
        caption: "Ulrich Museum",
        link: "https://www.youtube.com/watch?v=oyOL-l_2obg",
        image: 'Placeholder Image-14.jpg',
        verb: 'Play'
    },
    {
        date: 'November 1, 2007',
        title: "Permanent Art, Evanescent Life",
        caption: "Dot Earth Blog, interview with Andrew Revkin, NY Times",
        link: "https://archive.nytimes.com/dotearth.blogs.nytimes.com/2007/11/01/permanent-art-evanescent-life/",
        image: 'Placeholder Image-12.jpg',
        verb: 'Read'
    },
    {
        date: 'October 30, 2007',
        title: "\"Paintings of Nature's Comeback Kids\" by Andrew Revkin", 
        caption: "Dot Earth Blog, NY Times",
        link: "https://archive.nytimes.com/dotearth.blogs.nytimes.com/2007/10/30/paintings-of-natures-comeback-kids/",
        image: 'Placeholder Image-17.jpg',
        verb: 'Read'
    },
    {
        date: 'N/A',
        title: "Winn Newhouse Awards statement on disability",
        caption: "",
        link: "https://www.wnewhouseawards.com/isabellakirkland.html",
        image: 'Placeholder Image-13.jpg',
        verb: 'Read'
    }    
];


