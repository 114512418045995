<template>
    <div class="uneven-layout" :class="{'inverted':!!inverted}">
    <div class="left" :class="{'vertical-align--center': verticalCenter}">
      <slot name="left"></slot>
    </div>
    <div class="right" :class="{'vertical-align--center': verticalCenter}">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'UnevenLayout',
    props: {
        'inverted': {
            default: false,
            required: false,
            type: Boolean
        },
        'verticalCenter': {
            default: true,
            required: false,
            type: Boolean
        }
    }
}
</script>

<style>
.uneven-layout {
    display: grid;
    gap: 6%;
    grid-template-columns: 35.5% 58.5%;
}
.uneven-layout.inverted {
    grid-template-columns: 58.5% 35.5%;
}
.uneven-layout img {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
    .uneven-layout,
    .uneven-layout.inverted {
        grid-template-columns: 100%;
        gap: 0;
    }
    .uneven-layout.inverted .right {
        grid-column-start: 1;
        grid-row-start: 1;
    }
}
</style>
