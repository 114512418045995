<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import talksAndAppearances from '../data/talks-and-appearances';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'WritingsAndTalksPage',
    metaInfo() {
        const title = 'Writings & Talks – Isabella Kirkland';
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },  
  components: {
    GlobalHeader,
    GlobalFooter
  },
  computed: {
        talksAndAppearancesData: function() {
            return talksAndAppearances;
        }
  }
}
</script>

<template>
  <div id="articles-page">
    <GlobalHeader/>
    <div class="content pad">
        <div>
            <!-- <span class="sub-heading">Articles and Publications</span> -->
            <h3>Writings &amp; Talks</h3>
        </div>
        <div class="grid--rows-of-three">
            <div class="talk" v-for="talk in this.talksAndAppearancesData" :key="talk.title">
                <img class="image" :src="require(`@/assets/images/articles-and-publications-page/${talk.image}`)" />
                <div class="information">
                    <span class="sub-heading">{{ talk.date }}</span>
                    <h5>{{ talk.title }}</h5>
                    <p>{{ talk.caption }}</p>
                    <a :href="talk.link" target="_blank" rel="nofollow" class="link">
                        <audio v-if="talk.audio" controls :src="talk.audio" />
                        <template v-else>
                            {{ talk.verb }}
                            <img class="chevron" src="@/assets/svg/chevron-right.svg"/>
                        </template>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style>
.information {
    padding: 1rem 0;
}
.information span,
.information h5,
.information p,
.information a {
    padding: 0;
    margin: 0;
}
@media screen and (max-width: 768px) {
    .information {
        padding: 1rem 0;
    }
    .information span,
    .information h5,
    .information p,
    .information a {
        padding: 0;
        margin: 0;
    }
}
.information span,
.information p {
    margin-bottom: 1em;
}
.talk {
    margin-bottom: 3rem;
}
.talk .image {
    width: 100%;
}
.link {
    margin: 2.5rem 0;
    text-decoration: none;
    width: fit-content;
}
#articles-page .chevron {
    position: relative;
    top: 0.2em;
    left: 0.2em;
    width: 1rem;
}
@media screen and (max-width: 768px) {
    .link {
        margin: 1rem 0;
    }
}
</style>
