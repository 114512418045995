<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import UnevenLayout from '../components/layout/UnevenLayout.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'ContactPage',
    metaInfo() {
        const title = 'Contact – Isabella Kirkland';
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        UnevenLayout
    }
}
</script>

<template>
  <div id="contact-page">
    <GlobalHeader/>
    <div class="content pad">
     
      <UnevenLayout :verticalCenter="false">
        <template #left>
          <h2>Thank you <br/>for your interest.</h2>
          <br class="desktop-only"/>
          <p>For inquiries about purchasing an art work or reproducing an image, contact <a href="mailto:info@hosfeltgallery.com">info@hosfeltgallery.com</a>.</p>
          <br class="desktop-only"/>
          <p>For all other inquiries, <a href="mailto:info@isabellakirkland.com">click here</a>.<br/>Sausalito, California</p>
        </template>
        <template #right>
          <img class="portrait" src="@/assets/images/isabella-kirkland-portrait.jpg"/>
          <p class="portrait-credit">Photo: Christoper Michel</p>
        </template>
      </UnevenLayout>
      <hr/>
      <UnevenLayout :inverted="true" :verticalCenter="false">
        <template #left>
          <img src="@/assets/images/home-page/taxa-prints-tn.png"/>
        </template>
        <template #right>
          <h2>Taxa Prints</h2>
          <br class="desktop-only"/>
          <p>This is a suite of signed, numbered prints of the six Taxa paintings. They are presented in a silk-covered clamshell box and include the 48-page Taxa catalog with an introduction by E. O.Wiison.</p>
          <br class="desktop-only"/>
          <ul>
            <li>Created by Isabella Kirkland between 1999 and 2005 published by Feature Inc., NY.</li>
            <li>Printed at Electric Works, San Francisco by Nicolas Widman, 2006</li>
            <li>Digital imaging and color correction by Maizie Gilbert</li>
            <li>Printed on Moab Entrada Natural 330 g/sm in an edition of 50</li>
          </ul>
          <p>Please email for more information<br/><a href="mailto:info@hosfeltgallery.com">info@hosfeltgallery.com</a></p>
        </template>
      </UnevenLayout>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style>
article img {
  width: 100%;
}
.contact-details {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.contact-details > div > img {
  margin-bottom: 1rem;
  width: 2rem;
}
.contact-details .chevron {
    position: relative;
    top: 0.2em;
    left: 0.2em;
    width: 1rem;
}
.desktop-only {
  display: none;
}
#contact-page img.portrait {
  width: 40vw;
  /* float: right; */
}
#contact-page .portrait-credit {
    float: left;
    font-size: 0.8em;
    padding-top: 0;
}
@media screen and (max-width: 768px) {
  #contact-page p {
    margin-top: 0;
    padding-top: 0;
  }
  #contact-page img.portrait {
    width: 100vw;
    float: left;
  }
}
</style>
