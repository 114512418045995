<template>
    <footer>
        <div class="footer-content">
            <img class="logo" src="@/assets/svg/logo.svg" />
            <router-link :to="{ path: '/cookie-policy'}">Cookie Policy</router-link>
            <router-link :to="{ path: '/privacy-policy'}">Privacy Policy</router-link>
            <p>
                &copy; {{ new Date().getFullYear() }} Isabella Kirkland. All rights reserved.
            </p>
            <span></span>
            <span></span>
            <span></span>
            <div id="instagram-link">
                <a href="https://www.instagram.com/isabellakirkland" target="_blank">
                    <img id="instagram-logo" src="@/assets/images/instagram-logo.png" />
                </a>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'GlobalFooter'
}
</script>

<style scoped>
footer {
    margin: 5em 4em;
}
footer .logo {
    max-width: 240px;
}
footer p {
    padding-top: 0;
}
footer .footer-content {
    border-top: 1px solid black;
    padding-top: 2rem;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr fit-content(120px) fit-content(120px) fit-content(400px);
}
footer .footer-content a {
    padding: 0;
    text-decoration: none;
}
footer .footer-content .footer-links {
    display: flex;
    gap: 1.5rem;
}
footer .footer-content p {
    text-align: right;
}
#instagram-link {
    text-align: right;
}
#instagram-logo {
    height: 26px;
    width: 26px;
}
@media screen and (max-width: 900px) {
    footer {
        margin: 2em;
    }
    footer .footer-content {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
    footer .footer-content span {
        display: none;
    }
    footer .footer-content p {
        text-align: left;
        padding: 0;
    }
    #instagram-link {
        text-align: left;
    }
}
</style>
