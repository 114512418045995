<template>
    <div class="even-layout">
    <div class="left" :class="{'vertical-align--center': verticalCenter}">
      <slot name="left"></slot>
    </div>
    <div class="right" :class="{'vertical-align--center': verticalCenter}">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
    name: 'EvenLayout',
    props: {
        'verticalCenter': {
            default: true,
            required: false,
            type: Boolean
        }
    }
}
</script>

<style>
.even-layout {
    display: grid;
    gap: 6%;
    grid-template-columns: 47% 47%;
}
.even-layout img {
  max-width: 100%;
}
@media screen and (max-width: 768px) {
  .even-layout {
    grid-template-columns: 100%;
  }
}
</style>
