<template>
    <figure :style="{ backgroundImage: `url(${image})`, minHeight: (height || '80vh') }">
        <div class="surround">
            <router-link v-if="button && route" :to="{ path: route }" class="button">
                <img class="chevron" src="@/assets/svg/chevron-right.svg"/>
            </router-link>
            <router-link v-else-if="header && route" :to="route" class="link">
                <h2>{{ header }}</h2>
                <img class="chevron" src="@/assets/svg/chevron-right-white.svg"/>
            </router-link>
            <template v-else>
                <span v-if="subheader" class="sub-header">{{ subheader }}</span>
                <h2 v-if="header">{{ header }}</h2>
                <p v-if="caption">
                {{ caption }}
                </p>
            </template>
        </div>
    </figure>
</template>

<script>
export default {
    name: 'HeroSection',
    props: [
        'image',
        'subheader',
        'header',
        'caption',
        'button',
        'route',
        'height'
    ]
}
</script>

<style scoped>
figure {
    background-size: cover;
    color: white;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    align-content: center;
    margin: 0;
    max-height: 80vh;
    height: 50vh;
    width: 100vw;
}
figure > * {
    max-width: 40vw;
}
figure > h2 {
    max-width: 26vw;
}
figure .surround {
    background: rgba(0, 0, 0, 0.60);
    box-sizing: border-box;
    padding: 2em 2em 2em 7em;
    text-align: left;
}
h2 {
    text-align: left;   
}
p {
    margin-top: 1.5rem;
    text-align: left;
}
.button {
    border: none;
    display: block;
    margin-top: 1em;
    margin-bottom: 1em;
}
@media screen and (max-width: 768px) {
    figure {
        min-height: 75vh;
    }
    figure > *,
    figure > h2 {
        max-width: 100vw;
    }
    figure .surround {
        padding: 1em 4em 1em 1em;
        width: -moz-fit-content;
        width: fit-content;
        max-width: 70vw;
    }
}
.link {
    color: white;
    margin: 0;
    padding: 0;
    position: relative;
    text-decoration: none;
    width: fit-content;
    display: block;
}
.link h2 {
    padding-right: 1.3em;
}
.chevron {
    color: white;
    position: absolute;
    top: initial;
    right: 0;
    bottom: 0.3em;
    width: 4rem;
    transition: right 0.3s ease-out;
}
.link:hover .chevron {
    right: -0.5em; /* Adjust the value to control the slide distance */
    transition: right 1s cubic-bezier(.16,.67,.29,.87);
}
@media screen and (max-width: 768px) {
    .link {
        margin: 0rem 0;
    }
}
</style>
