import HomePage from './views/HomePage.vue';
import StatementAndAcknowledgementsPage from './views/StatementAndAcknowledgementsPage.vue';
import ProcessPage from './views/ProcessPage.vue';
import WritingsAndTalksPage from './views/WritingsAndTalksPage.vue';
import ContactPage from './views/ContactPage.vue';
import CollectionsPage from './views/CollectionsPage.vue';
import CollectionPage from './views/CollectionPage.vue';
import PaintingPage from './views/PaintingPage.vue';
import CurriculumVitaePage from './views/CurriculumVitaePage.vue';
import PaintingKeysPage from './views/PaintingKeysPage.vue';
import PaintingKeyPage from './views/PaintingKeyPage.vue';
import PrivacyPolicyPage from './views/PrivacyPolicyPage.vue';
import CookiePolicyPage from './views/CookiePolicyPage.vue';
import SearchPage from './views/SearchPage.vue';
// import PageNotFound from './views/PageNotFound.vue';

export default [
    {
        path: '/statement-and-acknowledgements',
        name: 'StatementAndAcknowledgementsPage',
        component: StatementAndAcknowledgementsPage
    },
    {
        path: '/process',
        name: 'ProcessPage',
        component: ProcessPage
    },
    {
        path: '/writings-and-talks',
        name: 'WritingsAndTalksPage',
        component: WritingsAndTalksPage
    },
    {
        path: '/contact',
        name: 'ContactPage',
        component: ContactPage
    },
    {
        path: '/collections',
        name: 'CollectionsPage',
        component: CollectionsPage
    },
    {
        path: '/collections/:collection',
        name: 'CollectionPage',
        component: CollectionPage
    },
    {
        path: '/collections/:collection/paintings/:painting',
        name: 'PaintingPage',
        component: PaintingPage
    },
    {
        path: '/',
        name: 'HomePage',
        component: HomePage
    },
    {
        path: '/curriculum-vitae',
        name: 'CurriculumVitaePage',
        component: CurriculumVitaePage
    },
    {
        path: '/species-keys',
        name: 'PaintingKeysPage',
        component: PaintingKeysPage
    },
    {
        path: '/species-keys/:collection/:painting',
        name: 'PaintingKeyPage',
        component: PaintingKeyPage
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicyPage',
        component: PrivacyPolicyPage
    },
    {
        path: '/cookie-policy',
        name: 'CookiePolicyPage',
        component: CookiePolicyPage
    },
    {
        path: '/search',
        name: 'SearchPage',
        component: SearchPage
    },
    // {
    //     path: '/:catchAll(.*)*',
    //     name: "PageNotFound",
    //     component: PageNotFound,
    // },
];