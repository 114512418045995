<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

// import EvenLayout from '../components/layout/EvenLayout.vue'
import UnevenLayout from '../components/layout/UnevenLayout.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
  name: 'StatementAndAcknowledgementsPage',
  metaInfo() {
        const title = 'Statement & Acknowledgements – Isabella Kirkland';
        
        const description = 'Accurate depiction of plants and animals has served the biological sciences for centuries. Once Carl Linnaeus built his taxonomic system to classify and organize species, the binomial Latin system still used, scientific illustration became an essential tool of accurate diagnostic differentiation.';

        const image = '/assets/images/isabella-kirkland-portrait-2.jpg';

        return generateMetaInfo(window.location.href, title, description, image);
  },
  components: {
    GlobalHeader,
    GlobalFooter,
    // EvenLayout,
    UnevenLayout
  }
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
      <UnevenLayout :verticalCenter="false">
        <template #left>
          <h3>Statement &amp; Acknowledgments</h3>
        </template>
        <template #right>
          <p>
                Accurate depiction of plants and animals has served the biological sciences for centuries. Once Carl Linnaeus built his taxonomic system to classify and organize species, the binomial Latin system still used, scientific illustration became an essential tool of accurate diagnostic differentiation. 
                <br/><br/>
                I choose to apply accuracy in a different service: my analog and intentionally durable works in oil reflect selected flora and fauna of earth that may well outlast the species depicted in them. Especially for highly evolved and niche dependent species, the paintings may serve as witness to their loss.
                <br/><br/>
                While this is often called the digital age, our formats are increasingly ephemeral. Operating systems do not communicate with each other across time. However, oil paintings can endure centuries, like a time capsule. These paintings are a hedge against a future distant enough, or even near enough, to lack both the plants and animals in them, and the means to retrieve their images. 
                <br/><br/>
                Deep detail is one way to slow people down from the warp speed and distortion of modern life.  In that slower, quieter moment, people may feel connected to the painted species in front of them and reflect upon their own impact on them. Humans may cause or prevent a species' survival or extinction through how we live our lives, what we eat, what we choose as work, and how we spend our money. We are not separate from nature, but a disproportionately large part of it. As the dominant species on this planet, we have an incumbent responsibility to the rest of its life. 
                <br/><br/>
                My paintings present nature as a rich and varied tapestry while the actual species of plants and animals that form the warp and weft threads of natures' fabric disappear at unprecedented rates, from place to place, bit by bit. Each generation of humans has a warped view of biological diversity; we are blind to slow, incremental change and cannot see the species richness of our grandparents' time. Nature in our own time seems rich and varied to us, particularly since we spend less and less time outside in it.
                <br/><br/>
                There can be no return to some mythic time long ago when things were golden, nature is in a constant state of change. But we must find ways to allow nature itself the time and space to repair some of the damage mankind has caused. To avoid the correction that ecologists usually see when an opportunistic species has a population boom and surge in resource use, an equally large die-off, nature's systems must remain functional: water and carbon cycles, ocean currents, soil health, boreal and tropical forests, etc. We must keep and regain as much species richness and diversity as possible, everywhere.  
                <br/><br/>
                And for that to happen, more of us must care. 
                <br/><br/>
                <strong>Isabella Kirkland<br/>2024</strong>
          </p>
        </template>
      </UnevenLayout>
      <div class="portrait-image-container">
        <img class="portrait-image" src="@/assets/images/isabella-kirkland-portrait-2.jpg" />
        <p class="portrait-credit">Photo: Christoper Michel</p>
      </div>
      <UnevenLayout :verticalCenter="false">
        <template #left>
          <h3>Acknowledgements</h3>
        </template>
        <template #right>
          <p>
                Research for my work takes me to museums and institutions far and wide. Scientists, from many  disciplines, have answered my questions, shared their expertise, and granted me access to museums, zoos, collections, libraries, and experiences. Other artists  past and present, photographers, and scientific illustrators have contributed to my perception of particular plants and animals. Thank you.
                <br/><br/>
                I am very grateful to my home institution, The California Academy of Sciences. CAS  has allowed me to be part of its community and to conduct my own research in its collections since I first began this biodiversity-based work in the early 1990’s.
                <br/><br/>
                All  who have bought that work over the years have funded my continued documentation of some of Earth’s rare flora and fauna and my activism on behalf of species that cannot advocate for themselves. You  hold  the paintings safely, to help them move into the future, to continue their advocacy.
                <br/><br/>
                My work would not be possible without  a wide network of friends: Renny Pritikin, Maria Porges, Ryan Phelan, Stewart Brand, and Julia Kirkland Berger provide a deep well of friendship and support that has helped all of this work grow and evolve. Your continued faith is mana.   Chris Tellis is my stalwart supporter, cheering section, and husband. Our son, Nate Tellis, is a gentle, yet astute critic. To those unnamed, know that you are deeply appreciated. 
                <br/><br/>
                <strong>IK, 2024</strong>
          </p>
        </template>
      </UnevenLayout>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
img {
    height: auto;
    width: 100%;
}
.portrait-image-container {
    padding: 3rem 12%;
    margin: auto;
    width: fit-content;
}
.portrait-credit {
    font-size: 0.8em;
    padding-top: 0;
}
table td:first-child {
    padding-right: 1em;
}
.even-layout .right {
  text-align: right;
}
@media screen and (max-width: 768px) {
  .even-layout .right {
    text-align: left;
  }
}
</style>
