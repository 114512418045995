<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'CookiePolicyPage',
    metaInfo() {
        const title = 'Cookie Policy – Isabella Kirkland';
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    },
    components: {
        GlobalHeader,
        GlobalFooter
    }
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
        <h2>Cookie Policy</h2>
        <h4>What Are Cookies:</h4>
        <p>Cookies are small pieces of data stored on your device by your web browser. We use cookies for various purposes to enhance your experience on our website.</p>
        <h4>Types of Cookies We Use:</h4>
        <strong>Google Analytics Cookies:</strong> We use Google Analytics to analyze website traffic and gather information about your visit. Google Analytics cookies help us understand how users interact with our site.
        <h4>Managing Cookies:</h4>
        <p>You can manage or disable cookies through your browser settings. Please note that certain features of our website may not function properly if you disable cookies.</p>
        <h4>Google Analytics:</h4>
        <p>Our website uses Google Analytics, a web analytics service provided by Google, Inc. Google Analytics uses cookies to analyze how users interact with the site. For more information on how Google Analytics uses cookies, please visit <a href="https://policies.google.com/privacy">Google's Privacy & Terms</a>.</p>
        <h4>Changes to the Cookie Policy:</h4>
        <p>We may update this Cookie Policy periodically. The latest version will always be available on this page.</p>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
.content {
    gap: 0em;
}
</style>
