<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import EvenLayout from '../components/layout/EvenLayout.vue'

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'ProcessPage',
    components: {
        GlobalHeader,
        GlobalFooter,
        EvenLayout
    },
    metaInfo() {
        const title = 'Process Time-lapse – Isabella Kirkland'
        
        // Description should be picked up as the first set of text on the page.

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title);
    }
}
</script>

<template>
  <div>
    <meta property="title" content="Process Time-lapse – Isabella Kirkland" />
    <meta property="og:title" content="Process Time-lapse – Isabella Kirkland" />

    <GlobalHeader/>
    <div class="content pad">
        <EvenLayout :verticalCenter="false">
            <template #left>
                <h2>Process Time-lapse</h2>
                <br/>
                <p>
                    From beginning of research to the final details, large complex paintings like this one take about a year to complete. The first iteration is a database of species that fit the subject of the painting.  Next come separate life-sized drawings on tracing paper of each species in the database. The 4 or 5 largest and most dominant of these can be rearranged  until a pleasing composition suggests itself, one that might follow an S, C, or X form using a branch, vase, or stairs, something that keeps the eye moving from shape to shape.  All the plants and animals from the list are fitted into that overall movement: each species redrawn in a position appropriate to the underlying structure. A final cartoon is transferred to a prepared background. Then each plant or animal must be painted correctly,  in concert and harmony with its neighbors, while inferring the light, distance, and air around it.
                    <br/><br/>
                    <strong>IK, 2024</strong>
                </p>
            </template>
            <template #right>
                <img src="@/assets/images/process-small.gif" />
            </template>
        </EvenLayout>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
</style>
