<script>
import OpenSeadragon from 'openseadragon';
window.OpenSeadragon = OpenSeadragon;

import BackTo from './BackTo.vue';
// import DownloadButton from './DownloadButton.vue';

export default {
    name: 'PaintingViewer',
    props: [
        'collection',
        'painting',
        'paintingKey',
        'keyCoords'
    ],
    components: {
        BackTo
        // DownloadButton
    },
    data: function() {
        return {
            showDescription: false,
            viewer: null,
            showTooltip: false,
            tooltip: {
                name: '',
                latin: '',
                facts: false
            },
            tooltipPosition: { x: 0, y: 0 }
        }
    },
    computed: {
        hasColouringPage: function() {
            return this.painting.colouringPage;
        },
        tileSources: function() {
            if(this.paintingKey) {
                return `/painting-key-files/${this.collection.slug}/${this.painting.slug}-${this.painting.originalSize}.dzi`;
            } else {
                return `/paintings/${this.collection.slug}/${this.painting.slug}-${this.painting.originalSize}.dzi`;
            }
        },
        tooltips: function() {
            if(this.keyCoords && this.viewer) {
                console.log('this.keyCoords',this.keyCoords);
                return Object.entries(this.keyCoords).map((_, index) => {
                    const key = index + 1;
                    return this.getKeyCoords(key);
                });
            }
            
            return [];
        }
    },
    mounted: function() {
        const viewerOptions = {
            id: 'painting',
            prefixUrl: '/openseadragon/images/',
            tileSources: this.tileSources,
            tabIndex: 0,
            blendTime: 0,
            alwaysBlend: false,
            showNavigationControl: true,
            immediateRender: true,
            defaultZoomLevel: 0,
            visibilityRatio: 0.85,
            zoomPerScroll: 1.1,
            zoomPerClick: 1.8,
            minZoomImageRatio: 0.9,
            maxZoomPixelRatio: 1.1,
            autoResize: true,
            showNavigator: true,
            navigatorPosition: "ABSOLUTE",
            navigatorHeight: 145,
            navigatorWidth: 190,
            navigatorAutoFade: true,
            navigatorBackground: "#000",
            navigatorBorderColor: "#FFF",
            navigatorDisplayRegionColor: "#FFF",
            navigatorAutoResize: false,
            controlsFadeDelay: 4000,
            homeButton: "ztb-home",
            fullPageButton: "ztb-full-image",
            zoomInButton: "ztb-zoom-in",
            zoomOutButton: "ztb-zoom-out",
            toolbar: "minimap-controls",
            navigatorOpacity: 1,
            gestureSettingsMouse: {
                scrollToZoom: true,
                pinchToZoom: false,
                clickToZoom: true,
                dblClickToZoom: false
            },
            gestureSettingsTouch: {
                scrollToZoom: false,
                pinchToZoom: true,
                clickToZoom: false,
                dblClickToZoom: true
            }
        };

        this.viewer = OpenSeadragon(viewerOptions);

        document.addEventListener("fullscreenchange", this.fullscreenchanged);

        //  

        const mouseTracker = new OpenSeadragon.MouseTracker({
            element: this.viewer.container,
            moveHandler: (event) => {
                const position = this.viewer.viewport.pointFromPixel(event.position);
                // Grab the first image (there is only one).
                const tiledImage = this.viewer.world.getItemAt(0);

                // Get original image dimensions
                const imageWidth = tiledImage.source.dimensions.x;
                const imageHeight = tiledImage.source.dimensions.y;
                console.log('position', position.x, (position.y * (imageWidth / imageHeight)));
                console.log('event.originalEvent',event.originalEvent);
                // Update tooltip position
                this.tooltipPosition = {
                    x: event.originalEvent.layerX,
                    y: event.originalEvent.layerY
                };

                // Check if the mouse pointer is over a tooltip
                for(let tooltip of this.tooltips) {
                    try {
                        if(tooltip.rect.containsPoint(position)) {
                            this.showTooltip = true;
                            const key = this.paintingKey.key.find(k => k.index === ~~(tooltip.key));
                            this.tooltip.name = `#${tooltip.key} ${key.name ? key.name : ''}`;
                            this.tooltip.latin = key.latin;
                            this.tooltip.facts = key.facts ? key.facts : false;
                            break;
                        }
                    } catch(e) {
                        console.warn(`Error when attempting to analyse mouse pointer position for tooltip: ${tooltip}`, e);
                    }
                    this.showTooltip = false;
                }
            },
            leaveHandler: () => {
                this.showTooltip = false;
            }
        });

        mouseTracker.setTracking(true);
    },
    beforeUnmount: function() {
        this.viewer.destroy();
    },
    methods: {
        fullscreenchanged: function() {
            if(!document.fullscreenElement) {
                setTimeout(() => {
                this.$forceUpdate(); 
                },1000);
            }
        },
        getKeyCoords: function(key) {
            try {
                // Grab the first image (there is only one).
                const tiledImage = this.viewer.world.getItemAt(0);

                // Get original image dimensions
                const imageWidth = tiledImage.source.dimensions.x;
                const imageHeight = tiledImage.source.dimensions.y;

                const ratio = imageWidth / imageHeight;
                const orientation = ratio > 1 ? 'landscape' : 'portrait';

                const coord = this.keyCoords[key];
                const radius = 0.03;
                const x = coord.x - (radius / 2);
                const y = (coord.y / ratio) - (radius / 2);
                const width = radius;
                const height = radius;
                console.log('orientation', orientation);
console.log('key', key, coord, x, y);
                return {
                    x,
                    y,
                    radius,
                    width,
                    height,
                    key,
                    rect: new OpenSeadragon.Rect(x, y, width, height)
                };
            } catch(e) {
                console.warn(`Error when obtaining coords for key: ${key}`, e);
            }
        },
        toggleDescription: function() {
            this.showDescription = !this.showDescription;
        },
        scrollToHeader: function() {
            const targetH2 = document.getElementById('heading');

            // Calculate the offset of the target H2 element
            const targetOffset = targetH2.getBoundingClientRect().top + window.scrollY;

            // Scroll to the target with a smooth animation
            window.scrollTo({
                top: targetOffset,
                behavior: 'smooth'
            });
        },

        // Zoom to particular key
        focusOnKey: function(key) {
            const coords = this.getKeyCoords(key);

            const center = coords.rect;

            this.viewer.viewport.panTo(center, false);
            this.viewer.viewport.zoomTo(2, center, false);
        }
    }
}
</script>

<template>
    <div id="painting-container">
        <div id="painting-nav">
            <BackTo :path="`/collections/${collection.slug}`" text="paintings" :inline="true" />
            <a @click="toggleDescription">Description</a>
            <router-link v-if="painting.colouringPage && !paintingKey" :to="{ path: `/species-keys/${collection.slug}/${painting.slug}` }">Species Key</router-link>
            <router-link v-if="painting.colouringPage && paintingKey" :to="{ path: `/collections/${collection.slug}/paintings/${painting.slug}` }">Painting</router-link>
        </div>
        <transition name="slide">
            <div id="key-facts" v-click-outside="toggleDescription" :class="{ 'show': showDescription }">
                <img class="close-button" @click="toggleDescription" src="@/assets/svg/cross.svg" />
                <!-- <h2>Key Facts</h2> -->
                <div class="key-facts-row">
                    <strong>Title</strong>
                    <span>{{ painting.name }}</span>
                </div>
                <div class="key-facts-row">
                    <strong>Date</strong>
                    <span>{{ painting.year }}</span>
                </div>
                <div class="key-facts-row">
                    <strong>Medium</strong>
                    <span>{{ painting.medium }}</span>
                </div>
                <div class="key-facts-row">
                    <strong>Dimensions</strong>
                    <span>{{ painting.dimensions }}</span>
                </div>
                <div v-if="painting.description.length > 0" class="description">
                    <!-- <strong>Description</strong> -->
                    <p v-html="painting.description"></p>
                </div>
                <!-- <div v-if="painting.colouringPage" class="colouringPage">
                    <h5>Download Painting Key</h5>
                    <DownloadButton :label="`${painting.slug}-painting-key.pdf`" :file="`/painting-key-files/${collection.slug}/${painting.slug.toLowerCase()}-painting-key.pdf`" text="Download" />
                </div> -->
            </div>
        </transition>
        <div id="painting"></div>
        <div v-if="showTooltip" class="tooltip" :style="{ top: tooltipPosition.y + 'px', left: tooltipPosition.x + 'px' }">
            <strong>{{tooltip.name}}</strong><br/>
            <i>{{tooltip.latin}}</i>
            <p v-if="tooltip.facts">{{tooltip.facts}}</p>
        </div>
        <div id="minimap-controls">
            <img id="ztb-zoom-out" title="Zoom out" src="@/assets/svg/minus-left-square.svg" />
            <img id="ztb-zoom-in" title="Zoom in" src="@/assets/svg/plus-left-square.svg" />
            <img id="ztb-home" title="Home" src="@/assets/svg/home-icon.svg" />
            <div id="ztb-full-image">
                <img id="fullscreen-icon" title="Fullscreen" src="@/assets/svg/fullscreen-icon.svg" />
                <img id="close-icon" title="Close" src="@/assets/svg/cross-icon.svg" @click.stop.prevent="() => window.location.reload()" />
            </div>
        </div>
        <div class="scroll-down-area">
            <button class="down-button" type="button" @click="scrollToHeader">
                <img class="chevron" src="@/assets/svg/chevron-down.svg"/>
            </button>
        </div>
    </div>
</template>

<style scoped>
#painting-container {
    position: relative;
}
#painting-nav {
    padding: 1rem 4rem;
}
#painting-nav a {
    cursor: pointer;
    padding-right: 2rem;

    /* Text/Regular/Medium */
    font-family: Lato;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 1.5rem */

    text-decoration: none;
}
#other-controls {
    display: none;
}
#other-controls.fullpage {
    display: block;
    position: absolute !important;
    top: 20px;
    right: 20px;
}
#key-facts {
    box-sizing: border-box;
    background: white;
    padding: 5rem 4rem;
    position: absolute;
    overflow-y: scroll;
    transform: translate(-100%, 0);
    transition: all 0.3s ease-out;
    height: 100%;
    width: 50vw;
    z-index: 1;
}
#key-facts.show {
    transform: translate(0, 0);
}
@media screen and (max-width: 768px) {
    #key-facts {
        width: 90vw;
    }
    #painting-nav {
        padding: 1rem 1rem;
    }
}
@media screen and (max-width: 640px) {
    #painting-nav a {
        padding-right: 1rem;
        font-size: 0.8rem;
    }
    #painting-nav .back-link.inline .chevron {
        top: 1.4em;
    }
}
.key-facts-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 1.5rem 0;
    border-bottom: 1px solid black;
}
#key-facts strong {
    text-transform: none;
}
.description {
    padding-top: 1.5rem;
}
.description strong {
    display: block;
    padding-bottom: 2rem;
}
#painting {
    background-color: black;
    margin: 0;
    height: calc(100vh - 11rem);
    width: 100vw;
}
@media screen and (max-width: 768px) {
    #painting {
        height: calc(100vh - 12rem);
    }
}
#minimap-controls {
    position: absolute !important;
    top: initial;
    bottom: 3em;
    right: 1em;
    z-index: 1;
}
#minimap-controls.fullpage {
    top: 20px;
    right: 20px;
    bottom: initial;
}
#minimap-controls #ztb-full-image #fullscreen-icon,
#minimap-controls.fullpage #ztb-full-image #close-icon {
    display: block;
}
#minimap-controls.fullpage #ztb-full-image #fullscreen-icon,
#minimap-controls #ztb-full-image #close-icon {
    display: none;
}
#minimap-controls img {
    cursor: pointer;
}
#minimap-controls #ztb-home {
    padding: 2px;
}
.scroll-down-area {
    text-align: center;
}
.down-button {
    border: none;
    background: none;
    padding: 1em;
    height: 2em;
    width: 2em;
    position: relative;
}
.down-button .chevron {
    height: 2em;
    width: 2em;
    display: block;
    cursor: pointer;
    left: -1em;
}
.colouringPage {
    display: grid;
    grid-template-columns: auto 120px;
    margin-top: 1.5rem;
    padding: 1.5rem 0;
    border-top: 1px solid black;
}
.close-button {
    position: absolute;
    top: 1.5em;
    right: 2em;
    cursor: pointer;
}

.tooltip {
  position: absolute;
  transform: translate(-0.25em, -0.25em);
  background-color: rgba(255, 255, 255, 0.8);
  color: black;
  padding: 5px;
  border-radius: 5px;
  pointer-events: none;
}
</style>
