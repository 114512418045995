<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'
import EvenLayout from '../components/layout/EvenLayout.vue'

// import DownloadButton from '../components/DownloadButton.vue'
import BackTo from '../components/BackTo.vue'

import paintingsData from '../data/paintings';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'PaintingKeysPage',
    metaInfo() {
        const title = 'Species Keys – Isabella Kirkland';
        
        const description = 'Discover and explore the several of Isabella Kirkland\'s paintings in more detail.';

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title, description);
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        EvenLayout,
        BackTo
        // DownloadButton
    },
    computed: {
        paintingKeys: function() {
            const paintingKeys = paintingsData.categories.reduce((a,c) => {
                return a.concat(c.paintings.filter(p => p.colouringPage && p.visible).map(p => ({ ...p, categoryName: c.name, categorySlug: c.slug })));
            }, []);

            return paintingKeys;
        }
    }
}
</script>

<template>
  <div id="painting-keys-page">
    <GlobalHeader/>
    <div class="content pad">
        <div>
            <!-- <span class="sub-heading">Discover and Download</span> -->
            <h2>Species Keys</h2>
            <!-- <p>Unlock the beauty and meaning behind each brushstroke with our Species Keys.</p> -->
        </div>
        <div>
            <BackTo path="/" text="Home" />
            <div class="grid--rows-of-two">
                <div v-for="(paintingKey, index) in paintingKeys" :key="index">
                    <router-link :to="{ path: `/species-keys/${paintingKey.categorySlug}/${paintingKey.slug}` }">
                        <img :src="`/painting-key-files/${paintingKey.categorySlug}/${paintingKey.slug}-painting-key-tn.jpg`" />
                    </router-link>
                    <EvenLayout :verticalCenter="false">
                        <template #left>
                            <router-link :to="{ path: `/species-keys/${paintingKey.categorySlug}/${paintingKey.slug}` }">
                                <h5>{{ paintingKey.categoryName }} - {{ paintingKey.name }}</h5>
                            </router-link>
                        </template>
                        <template #right>
                            <!-- <DownloadButton :label="`${paintingKey.name}-painting-key.pdf`" :file="`/painting-key-files/${paintingKey.categorySlug}/${paintingKey.name.toLowerCase()}-painting-key.pdf`" text="Download" /> -->
                        </template>
                    </EvenLayout>
                </div>
            </div>
        </div>
    </div>
    <!-- <div class="content pad footer-content">
        <h2>Discover the Process</h2>
        <p>Learn more about the process of the paintings</p>
        <button class="button">Learn More</button>
    </div> -->
    <GlobalFooter/>
  </div>
</template>

<style scoped>
.grid--rows-of-two a {
    text-decoration: none;
}
.grid--rows-of-two h5 {
    padding-top: 0;
}
.grid--rows-of-two img {
    object-fit: cover;
    width: 100%;
}
.footer-content {
    text-align: center;
}
</style>

<style>
#painting-keys-page .download-button {
    position: relative;
    top: 6px;
}
#painting-keys-page .even-layout {
    margin-top: 1em;
}
#painting-keys-page .even-layout .right {
    text-align: right;
}
@media screen and (max-width: 768px) {
    h5 {
        padding-bottom: 0;
    }
    #painting-keys-page .even-layout .right {
        text-align: left;
        padding-bottom: 2em;
    }
}
</style>
