<script>
import GlobalHeader from '../components/global/GlobalHeader.vue'
import GlobalFooter from '../components/global/GlobalFooter.vue'

import ImageWithContent from '../components/ImageWithContent.vue'
import BackTo from '../components/BackTo.vue'

import paintingsData from '../data/paintings';

import generateMetaInfo from '../helpers/generateMetaInfo.js';

export default {
    name: 'CollectionsPage',
    metaInfo() {
        const title = 'Paintings – Isabella Kirkland';
        
        const description = 'Discover and explore the different collections of Isabella Kirkland\'s paintings.';

        // Image should be picked up as the first image on the page.

        return generateMetaInfo(window.location.href, title, description);
    },
    data: function() {
        return {
            viewAll: false
        };
    },
    components: {
        GlobalHeader,
        GlobalFooter,
        ImageWithContent,
        BackTo
    },
    computed: {
        'collections': function () {
            const collections = this.viewAll ? paintingsData.categories : paintingsData.categories.slice(0, 9999);

            return collections.map(collection => {
                const thumbnailSlug = collection.paintings[collection.hero-1].slug;
                return {
                    title: collection.name,
                    caption: collection.date,
                    image: `/painting-thumbnails/${collection.slug}/${thumbnailSlug}-tn.jpg`,
                    route: `collections/${collection.slug}`
                };
            });
        }
    },
}
</script>

<template>
  <div>
    <GlobalHeader/>
    <div class="content pad">
        <div>
            <span class="sub-heading">Paintings</span>
            <h2>Subject</h2>
            <!-- <p>Explore a collection of stunning masterpieces select a subject below</p> -->
        </div>
        <div>
            <BackTo path="/" text="Home" />
            <div class="grid--rows-of-three">
                <ImageWithContent 
                    v-for="(collection, index) in collections"
                    :key="collection.title"
                    :index="index"
                    :image="collection.image"
                    :header="collection.title"
                    :caption="collection.caption" 
                    :route="collection.route"
                />
            </div>
            <!-- <button v-if="!viewAll" @click="() => { viewAll = true; }" class="button">View all</button> -->
        </div>
    </div>
    <GlobalFooter/>
  </div>
</template>

<style scoped>
button {
    margin: 0 auto;
}
</style>

<style>
div.grid--rows-of-three figure h5 {
    padding-bottom: 0;
}
</style>
